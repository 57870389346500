<template>

    <!--XXX <div id="all" class="live-chat-none" v-if="IP">--->
        
    <div id="all" class="live-chat-none" v-if="clientIP.trim().length>0" :key="0">
        
        <!---CLOSED-->
        <div id="live-chat"  class="messenger"   @mouseover="mover()"    @mouseleave="mout()">
            <div id="icon-space" class="messenger-in"  >
                <img    id="messenger-icon"  class="messenger-icon"    :src="swgPath"     />
            </div>

            <div id="offer-title" class="messenger-in-right startChat " @click="startChat()" />
        </div>
        <!---CLOSED-->

        <div id="live-chat-open" class="display-none" v-click-outside="liveChatClose">
        
            <div id="live-chat-open-in"  class="live-chat-open-in border" >

                <!---HEAD OFFLINE XXX-->
                <!---XXX <div v-if="!onLineBroker && !clientIsAdmin" class="live-chat-open-offline-content">-->
                <div v-if="!brokerIsOnline && !clientIsAdmin" class="live-chat-open-offline-content">
                        {{ $t('chat.2') }} <br />
                        {{ $t('chat.5') }}<br />
                        <span class="offline">OFFLINE</span>    
                </div>

                <!---HEAD ONLINE -->

                <!---XXX <div v-if="onLineBroker && !clientIsAdmin" class="live-chat-open-online-content"> -->
                <div v-if="brokerIsOnline && !clientIsAdmin" class="live-chat-open-online-content">

                    <div class="live-chat-open-online-content-left">
                        <div class="live-chat-open-online-content-left-name">
                            {{oBrokerRecord.titleBefore + ' ' +  oBrokerRecord.name + ' ' + oBrokerRecord.surName}}
                        </div>
                        <div class="live-chat-open-online-content-left-status">
                            {{ $t('chat.3') }} <span class="online">ONLINE</span>  
                        </div>
                        <div class="live-chat-open-online-content-left-appel">
                            {{  $t('chat.4')}}:
                        </div>                        
                    </div>

                    <div class="live-chat-open-online-content-right">
                        <img :src="httpsPath + 'brokers/' + oBrokerRecord.photo" class="live-chat-open-photo" alt="" />
                    </div>                    

                </div>
                <!---HEAD ONLINE-->

                <!---HEAD ADMINISTRATOR -->
                <!--XXX <div v-if="clientIsAdmin">-->
                <div v-if="clientIsAdmin">

                    <div @click="openSelector()" class="select-senders-all">

                        <div id="senders-selector" class="select-senders" v-html="$t('chat.25')"   />

                        <div id="senders-icon" class="icon">
                            <i class="fa-solid fa-chevron-down"></i>
                        </div>                    
                    </div>

                    <div id="senders-selector-content" class="selector-content">
                        <div id="senders-list"  />
                    </div>
                </div>
                <!---HEAD ADMINISTRATOR-->


                <!---OFFLINE AFTER SEND-->
                <div id="comunication-after" class="display-none comunication-after-send" >
                    
                    <div id="after-1" class="after-send">{{ $t('chat.17') }}.</div>    

                    <div id="after-1" class="after-send">{{ $t('chat.18') }} <span class="online">ONLINE</span>.</div>    

                    <div id="after-1" class="after-send">{{ $t('chat.19') }} <span class="online">ONLINE</span>, {{ $t('chat.20') }}.</div>    
                </div>
                <!---OFFLINE AFTER SEND-->


                <!---OFFLINE SEND MESSAGE-->
                <!--XXX <div v-if="!onLineBroker && !clientIsAdmin" id="comunication-with" class="display-none comunication-offline" >-->
                <div v-if="!brokerIsOnline && !clientIsAdmin" id="comunication-with" class="display-none comunication-offline" >

                    <div id="offline-1" class="comunication-offline content-1 ">
                        {{ $t('chat.6') }} <span class="comunication-offline offline">OFFLINE</span>, {{ $t('chat.7') }}
                    </div>

                    <div id="offline-2" class="comunication-offline content-2 ">
                        {{ $t('chat.8') }} <span class="comunication-offline online">+421 948 818 818</span>.
                    </div>
                    
                    <div id="offline-3" class="comunication-offline content-3 ">
                        {{ $t('chat.9') }} <span class="comunication-offline online">barbora.kovacova@818.sk</span>.
                    </div>
                    
                    <div id="offline-4" class="comunication-offline content-4 ">
                        {{ $t('chat.10') }} <span class="comunication-offline online">{{ $t('chat.11') }}</span>.
                    </div>

                    <div id="offline-5" class="comunication-offline content-5 ">

                        {{$t('chat.13')}}: 

                        <input  @keyup="msgOnly()" 
                                :value="clientName" 
                                type="text" 
                                id="client-identify" 
                                class="welcome" 
                                :placeholder="$t('chat.12')"  
                                autocomplete="off"  />
                                
                    </div>

                    <div id="offline-6" class="comunication-offline content-6 ">

                        {{$t('chat.14')}}.  <br />
                        ({{ $t('chat.16') }})


                        <textarea @keyup="msgOnly()" id="client-message" class="message-only"  :placeholder="$t('chat.22') + '.'"  />

                    </div>

                    <div id="offline-6" class="comunication-offline content-7 ">
                        
                        <div @click="sendOffline" id="send-message" class="start-send send-message-off">{{$t('chat.15')}}</div>
                    </div>

                </div>
                <!---OFFLINE SEND MESSAGE-->
            </div>

            <!---WORK SPACE ADMINISTRATOR-->
            <!---XXX <div v-if="clientIsAdmin">--->
            <div v-if="clientIsAdmin">

                <div v-if="clientIsAdmin" id="adminstrator-space" class="online-chat-space display-none ">

                    <div id="messages-admin" class="messages-admin" />

                    <!----->
                    <textarea   id="admin-message" 
                                @keyup="newAdminMessage($event, 'isMessage')"              
                                class="new-message-input"   />                    

                </div>

            </div>
            <!---WORK SPACE ADMINISTRATOR-->
            

            <!---ONLINE WORK SPACE-->
            <!---XXX <div v-if="onLineBroker" id="online-space" class="online-chat-space display-none ">-->
            <div v-if="brokerIsOnline" id="online-space" class="online-chat-space display-none ">


                <div id="chat-01"   class="chat sector-1">
                    <span id="appel-welcome" v-if="clientName.toString().trim().length===0">{{ $t('chat.13') }}:</span>
                    <span id="appel-welcome" v-else>{{ $t('chat.21') }}:</span>
                </div>

                <input  v-if="clientName.toString().trim().length===0"  
                        id="chat-identity" 
                        :placeholder="$t('chat.12')"  
                        autocomplete="off"  
                        class="chat-identity" 
                        @keyup="newMessage($event, '')"  
                        type="text" 
                />

                <input  v-else  
                        id="chat-identity" 
                        :placeholder="$t('chat.12')"  
                        autocomplete="off"  
                        class="chat-identity" 
                        @keyup="newMessage($event, '')"  
                        :value="clientName"
                        type="text" 
                />

                <div id="messages-id" class="messages" />

                <textarea   id="new-message" 
                            @keyup="newMessage($event, 'isMessage')"       
                            class="new-message-input"   />

                <div @click="sendOnline"   id="send-new-message" class="send-new-message-off">
                    {{$t('chat.15')}}
                </div>
                    
            </div>                
            <!---ONLINE WORK SPACE-->
        </div>
    </div>

</template>

<script>
    import vClickOutside from "click-outside-vue3"

    import oStyles from "@/assets/styles/StylesSets.json"

    import axios from "@/assets/modules/Axios.js"
    import obj from "@/assets/modules/ObjectMethods.js"
    import dt from "@/assets/modules/DateTime.js"
    import url from "@/assets/modules/URLMethods.js"
    
    export default {
        created() {
            //window.addEventListener('click', this.backgroundEvents);
            //window.addEventListener('scroll', this.backgroundEvents);
            //window.addEventListener('mousemove', this.backgroundEvents);
            

            window.addEventListener('scroll', this.setViewAndNone);
            this.interval = setInterval(() => this.backgroundEvents(), this.recursiveBackgroundEvents);


        },        
        directives: {
            clickOutside: vClickOutside.directive
        }, 
        data() {
            return {
                AXIOS:  new axios(this),
                OBJ:    new obj(this),
                DT:     new dt(this),
                URL:    new url(this),

                brokerStyles:this.createStyle(oStyles, 'broker'),
                clientStyles:this.createStyle(oStyles, 'client'),
                msgHead:this.createStyle(oStyles, 'msg_head'),
                sender:this.createStyle(oStyles, 'sender'),
                senderIn:this.createStyle(oStyles, 'sender-in'),
                senderDel:this.createStyle(oStyles, 'sender-del'),

                swgPath: localStorage.swgPath+'interview-icon.svg',
                intervals:[],
                offIntervals:[],
                openChatOffer:false,
                changeAnimation:true,
                isStartChat:false,
                isLiveChatClose:false,

                httpsPath:localStorage.httpsPath,

                oCommunication:[],
                oNewCommunication:[],

                oAdminCommunication:[],

                oSendersForAdmin:[],

                openSenderSector:false,


                rerender:0,

                //0 - client - online administrator
                //1 - administrator  imitatation
                //2 - client - offline administrator
                //imitation:0,

                //=============================================
                //Rastislav     178.143.43.159
                //Barborka      185.75.139.242

                imitationIP:'',

                choiceClientForAdmin:0,

                clientIP:'',
                oClientInfo:[],
                clientId:0,
                clientName:'',
                clientIsAdmin:false,

                //7 sec recursive events
                recursiveBackgroundEvents:5000,
                recursiveMaxTime:7000,

                //1 Hour online field
                brokerOnlineLimit:3600000,

                //3 mins
                brokerSetOnline:60000,
                
                //1 Min online field
                //onlineLimit:60000,

                //Control online interval 10 seconds
                onlineControlInterval:10000,

                lastTimeControl:'',
                

                oBrokerInfo:[],
                oLastBrokerInfo:[],
                brokerIsOnline:false,
                brokerOnlineTime:'',
                brokerId:0,
                brokerName:'',
                oBrokerRecord:[]
            }
        },
        methods: {

            backgroundEvents() {


                //console.log(screen.width + ' :: ' + screen.height)
                //Get IP
                if(this.clientIP.toString().trim().length===0)  this.AXIOS.getIPaddress('clientIP')

                //Get client all info
                if(this.OBJ.count(this.oClientInfo)===0 && this.clientIP.trim().length>0) 
                    this.AXIOS.getConnectRecord('oClientInfo', this.clientIP)

                //Get client info for chat 
                if(this.OBJ.count(this.oClientInfo)>0) {
                    //console.log('SETS')
                    //console.log(this.oClientInfo)
                    this.clientId = this.oClientInfo[0].id
                    this.clientName = this.oClientInfo[0].identify
                    this.clientIsAdmin = this.oClientInfo[0].admin > 0
                }

                if(this.clientId>0) {
                    this.AXIOS.currentSetConnect(this.clientId)
                }

                //Get broker all info
                
                //this.clientId>0 && 
                if(!this.clientIsAdmin && this.brokerId===0) {
                    this.AXIOS.getBrokerInfo('oBrokerInfo')
                    if(this.OBJ.count(this.oBrokerInfo)>0 && this.brokerId===0) {
                        this.oLastBrokerInfo = this.oBrokerInfo
                        for(let broker of this.oBrokerInfo) {
                            this.oBrokerInfo = []
                            this.oBrokerInfo.push(broker)
                            this.brokerId = broker.id
                            this.brokerName = broker.identify            
                            this.brokerOnlineTime = broker.connect
                            this.broker_id = broker.broker_id
                            if(broker.online !== '0000-00-00 00:00:00') {
                                if(this.DT.getTimeLimit(new Date(), broker.online)<this.brokerOnlineLimit) {
                                    this.brokerIsOnline = true
                                    console.log('TRUE 1')
                                    this.brokerOnlineTime = broker.online
                                    break
                                }
                            }
                        }
                    }
                }

                if(this.broker_id>0 && this.OBJ.count(this.oBrokerRecord)===0) {
                    this.AXIOS.getBrokerRecord('oBrokerRecord', this.brokerId)
                }


                if(this.clientId>0 && this.brokerId>0) {
                    //console.log('AAA')
                    let diff = this.DT.getTimeLimit(this.DT.stdFromJSDateTime(new Date()), this.lastTimeControl)
                    //console.log(diff + ' :: ' + this.onlineControlInterval)
                    if(diff >= this.onlineControlInterval ||
                        this.lastTimeControl.length===0)    {
                            //console.log('CCC')
                            this.lastTimeControl = this.DT.stdFromJSDateTime(new Date())      
                            //console.log('READ :: '+this.brokerId)
                            this.AXIOS.getConnectRecord('oBrokerInfo', '', this.brokerId)
                            
                    }
               }

               if(this.OBJ.count(this.oBrokerInfo)>0 && this.OBJ.count(this.oLastBrokerInfo)>0) {
                    this.oLastBrokerInfo = this.oBrokerInfo
                    this.brokerIsOnline = false
                    this.brokerOnlineTime = ''
                    if(this.oBrokerInfo[0].online !== '0000-00-00 00:00:00') {
                        if(this.DT.getTimeLimit(new Date(), this.oBrokerInfo[0].online)<this.brokerOnlineLimit) {
                            this.brokerIsOnline = true
                            this.brokerOnlineTime = this.oBrokerInfo[0].online                                    
                        }
                    }

                    if(!this.brokerIsOnline)    {
                        
                        if(this.DT.getTimeLimit(new Date(), this.oBrokerInfo[0].connect)<this.brokerSetOnline ) {
                            this.brokerIsOnline = true
                            this.brokerOnlineTime = ''
                        }
                    }

               }

               let sendersSelector = document.getElementById('senders-selector') 
               if(sendersSelector!==null) {
                    this.AXIOS.getSenders('oSendersForAdmin')

                    if(this.OBJ.count(this.oSendersForAdmin)>0)
                        sendersSelector.innerHTML = this.$t('chat.25') + ': ' + this.OBJ.count(this.oSendersForAdmin)
                    else
                        sendersSelector.innerHTML = this.$t('chat.25')
                }
               
                //console.log(this.brokerOnlineTime)
                //console.log(this.clientId + ' :: ' + this.clientIP + ' / ' + this.rerender)
                //console.log(this.brokerId)
                //console.log(this.brokerIsOnline)
                //console.log(this.oBrokerRecord)

                if(document.getElementById('messages-id') !==null) {
                    this.AXIOS.getMessagesChat(this.clientId, 1, 'oNewCommunication')
                }


                if(this.choiceClientForAdmin>0)  {
                    if(document.getElementById('messages-admin') !==null) {
                        //1 is temporary
                        this.AXIOS.getMessagesChat(this.choiceClientForAdmin, 1, 'oNewCommunication')
                    }
                }

                if(this.OBJ.count(this.oNewCommunication)>0) {
                    
                    let tempoId = 0
                    let messageBox
                    //1 is temporary

                    if(this.choiceClientForAdmin>0) {
                        messageBox = 'messages-admin'
                        tempoId = this.choiceClientForAdmin
                    } else {
                        messageBox = 'messages-id'
                        tempoId = this.clientId
                    }
                    this.refreshMessagesBox(    messageBox, 
                                                this.oNewCommunication,
                                                tempoId, 1)
                    this.oNewCommunication = []
                }

            },

            defineSenderId(id=0)  {

                let adminstratorSpace = document.getElementById('adminstrator-space')
                if(adminstratorSpace!==null)    adminstratorSpace.classList.remove('display-none')

                if(id<1 || typeof id === 'undefined')    return

                //1 is temporary
                this.AXIOS.getMessagesChat(id, 1, 'oAdminCommunication')

                //1 is temporary
                setTimeout(() => {  this.refreshMessagesBox(    'messages-admin', 
                                                                this.oAdminCommunication,
                                                                id,
                                                                1); console.log( this.oAdminCommunication) }, 500);
                
                this.choiceClientForAdmin = id
                this.closeSelector()
            },
            deleteSender(id=0)  {
                console.log('deleteSender :: ' + id)
                if(id<1 || typeof id === 'undefined')    return
                console.log('start :: ' + id)
                this.AXIOS.removeSender(id, 'oSendersForAdmin')
                
                this.closeSelector()
            },
            closeSelector() {
                let sendersSelectors  = document.getElementById('senders-selector') 
                let sendersIcons      = document.getElementById('senders-icon')
                let selectorContent   = document.getElementById('senders-selector-content')    

                if(sendersSelectors===null || sendersIcons===null || selectorContent===null)  return

                this.openSenderSector = false
                if(this.openSenderSector)    {
                    document.getElementById('senders-selector').innerHTML = this.$t('chat.26')
                    sendersIcons.innerHTML = '<i class="fa-solid fa-chevron-up"></i>'
                } else {
                    document.getElementById('senders-selector').innerHTML = this.$t('chat.25') + ': ' + this.OBJ.count(this.oSendersForAdmin)
                    sendersIcons.innerHTML = '<i class="fa-solid fa-chevron-down"></i>'    
                }

                sendersIcons.innerHTML = '<i class="fa-solid fa-chevron-down"></i>'

                selectorContent.classList.remove('selector-open-animation')
                selectorContent.classList.add('selector-close-animation')

                this.clearSendersList()
            },

            openSelector()   {
                
                let sendersSelectors  = document.getElementById('senders-selector') 
                let sendersIcons      = document.getElementById('senders-icon')
                let selectorContent   = document.getElementById('senders-selector-content')    

                if(sendersSelectors===null || sendersIcons===null || selectorContent===null)  return

                this.openSenderSector = !this.openSenderSector
               
                if(this.openSenderSector)    {
                    document.getElementById('senders-selector').innerHTML = this.$t('chat.26')
                    sendersIcons.innerHTML = '<i class="fa-solid fa-chevron-up"></i>'
                } else {
                    document.getElementById('senders-selector').innerHTML = this.$t('chat.25') + ': ' + this.OBJ.count(this.oSendersForAdmin)
                    sendersIcons.innerHTML = '<i class="fa-solid fa-chevron-down"></i>'
                }
                    
                if(this.openSenderSector)    {
                    selectorContent.classList.remove('selector-close-animation')
                    selectorContent.classList.add('selector-open-animation')
                } else {
                    selectorContent.classList.remove('selector-open-animation')
                    selectorContent.classList.add('selector-close-animation')
                }
               
                this.fillToSendersList()  
            },
            clearSendersList() {
                let sendersList    =    document.getElementById('senders-list')
                if(sendersList===null)  return
                let html = ''
                sendersList.innerHTML = html
            },
            fillToSendersList() {
                let sendersList    =    document.getElementById('senders-list')
                if(sendersList===null)  return

                let html = ''
                sendersList.innerHTML = html
                this.oSendersForAdmin = []
                this.AXIOS.getSenders('oSendersForAdmin')

                setTimeout(() => {

                    if(document.getElementById('senders-selector').innerHTML.toString().trim().length===0)
                        document.getElementById('senders-selector').innerHTML = this.$t('chat.25') + ': ' + this.OBJ.count(this.oSendersForAdmin)

                    let sendersId = []
                    for(let oSender of this.oSendersForAdmin)   {
                        html += '<div style="'+this.sender+'">'
                        html += '<div  id="sender-'+oSender.id+'" style="'+this.senderIn+'"  title="' + this.$t('chat.23') +  ' ?">'+oSender.sender+'</div>'
                        html += '<div id="delete-'+oSender.id+'" style="'+this.senderDel+'" title="' + this.$t('chat.24') + ' ?"><i class="fa-solid fa-user-xmark"></i></div>'
                        html += '</div>'
                        sendersId.push({"id":oSender.id})
                    }
                    sendersList.innerHTML = html
                    let e
                    for(let sender of sendersId)    {
                        e = document.getElementById('sender-'+sender.id)
                        e.addEventListener('click', () => {this.defineSenderId(this.defineSenderId(sender.id))})
                        e = document.getElementById('delete-'+sender.id)
                        e.addEventListener('dblclick', () => {this.defineSenderId(this.deleteSender(sender.id))})
                    }                    
                }, 500);


            },

            refreshMessagesBox( messageContainer='messages-id',
                                oCommunication=[],
                                clientId=0,
                                brokerId=0)    {

                
                if(clientId<1 || typeof clientId === 'undefined') return
                if(brokerId<1 || typeof brokerId === 'undefined') return
                                    //console.log(oCommunication)
                
                if(document.getElementById(messageContainer)===null) return

                document.getElementById(messageContainer).innerHTML = '';

                let aTime
                let content = ''
                let style = ''
                for(let msg of oCommunication) {

                    aTime = this.DT.SKFormat(msg.send, 1).split(':')

                    if( parseInt(msg.from_id) ===parseInt(clientId) && 
                        parseInt(msg.to_id)===parseInt(brokerId)) 
                            style = this.clientStyles

                    if( parseInt(msg.from_id)===parseInt(brokerId) && 
                        parseInt(msg.to_id)===parseInt(clientId)) 
                            style = this.brokerStyles

                    content += '<div style="'+ style + '">'

                    content += '<div style="'+this.msgHead+'">'
                    content +=  msg.identify
                    content += '</div>'

                    content += '<div style="'+this.msgHead+'">'
                    content += '<b>'+ aTime[0] + ':' + aTime[1] + '</b> :: '+this.DT.SKFormat(msg.send, 0)
                    content += '</div>'
                    content += msg.message
                    content += '</div>'

                    content += '</div>'
                    
                }

                document.getElementById(messageContainer).innerHTML = content;

                let messagesId = document.getElementById(messageContainer)
                messagesId.scrollTop = messagesId.scrollHeight;
            },

            liveChatClose() {
                this.intervals.push(setTimeout(() => {this.isLiveChatClose = true}, 1000))
                if(!this.isLiveChatClose) return
                for(let interval of this.intervals) clearInterval(interval)

                let liveChat = document.getElementById("live-chat")
                if(liveChat!==null)    liveChat.classList = ''

                let iconSpace = document.getElementById("icon-space")
                if(iconSpace!==null)  iconSpace.classList = ''

                let messengerIcon = document.getElementById("messenger-icon")
                if(messengerIcon!==null) messengerIcon.classList = ''

                let offerTitle = document.getElementById("offer-title")
                if(offerTitle!==null)   {
                    offerTitle.innerHTML = ''
                    offerTitle.classList = ''
                    offerTitle.classList.add('messenger-in-right')
                    offerTitle.classList.add('startChat')
                }

                if(liveChat!==null)    liveChat.classList.add('messenger')

                if(iconSpace!==null)  iconSpace.classList.add('messenger-in')

                if(messengerIcon!==null) messengerIcon.classList.add('messenger-icon')

                let liveChatOpen = document.getElementById("live-chat-open")    
                if(liveChatOpen!==null) {
                    liveChatOpen.classList.add('display-none')
                    liveChatOpen.classList.remove('messenger-build')
                }

                if(liveChat!==null)    liveChat.classList.remove('display-none')

                let onlineSpace = document.getElementById("online-space")
                if(onlineSpace!==null)  onlineSpace.classList.add('display-none')

                let adminstratorSpace = document.getElementById("adminstrator-space")
                if(adminstratorSpace!==null)  adminstratorSpace.classList.add('display-none')                
                
                this.openChatOffer = false
                this.changeAnimation = true
                this.isStartChat = false
                this.isLiveChatClose = false
                this.showContentOffline(false, 1)
            },

            mover() {
                if(this.openChatOffer || !this.changeAnimation || this.isStartChat) return
                
                this.openChatOffer = true
                this.changeAnimation = false

                document.getElementById('messenger-icon').classList.add('messenger-icon-animate')
                document.getElementById('live-chat').classList.remove('messenger-not-hover') 
                document.getElementById('live-chat').classList.add('messenger-hover') 

                this.intervals.push(setTimeout(() => {this.changeAnimation = true}, 500))

                this.intervals.push(
                    setTimeout(() => {document.getElementById('messenger-icon').classList.remove('messenger-icon-animate')}, 1800)
                )

                this.intervals.push(
                    setTimeout(() => {
                        document.getElementById('offer-title').innerHTML = '<div id="start-chat-text" style="margin-top:15px;">' + this.$t('chat.1') + '</div>'
                        document.getElementById('offer-title').classList.add('messenger-in-right-add')
                    }, 1200)
                )
               
                this.intervals.push(
                    setTimeout(() => {
                        document.getElementById('offer-title').classList.add('messenger-in-right-add-static')
                        document.getElementById('offer-title').classList.remove('messenger-in-right-add')
                    }, 2500)
                )
            },

            mout() {

                if(!this.openChatOffer || !this.changeAnimation || this.isStartChat) return

                this.openChatOffer = false
                this.changeAnimation = false
                document.getElementById('offer-title').innerHTML = ''
                document.getElementById('offer-title').classList.remove('messenger-in-right-add-static')

                document.getElementById('messenger-icon').classList.add('messenger-icon-animate')
                document.getElementById('live-chat').classList.remove('messenger-hover') 
                document.getElementById('live-chat').classList.add('messenger-not-hover') 

                this.intervals.push(
                    setTimeout(() => {this.changeAnimation = true}, 500)
                )
                
                this.intervals.push(
                    setTimeout(() => {document.getElementById('messenger-icon').classList.remove('messenger-icon-animate')}, 1800)
                )
            },
            startChat() {

                for(let interval of this.intervals) clearInterval(interval)
                this.isStartChat = true

                setTimeout(() => {
                    document.getElementById('live-chat').classList.add('display-none')
                    document.getElementById('live-chat-open').classList.remove('display-none')
                    document.getElementById('live-chat-open').classList.add('messenger-build')
                }, 0);

                this.readMessagess(this.clientId, this.brokerId)

                
                setTimeout(() => {
                    this.showContentOffline(true, 2) 
                    this.refreshMessagesBox(    'messages-id', 
                                                this.oNewCommunication,
                                                this.clientId,
                                                this.brokerId)
                }, 500);
            },

            showContentOffline(show=true) {

                let comunicationWith
                comunicationWith = document.getElementById('comunication-with')
                let onlineSpace  = document.getElementById('online-space')

                if(show )    {

                    if(comunicationWith!==null) 
                        if(comunicationWith.classList.contains('display-none'))
                            if(document.getElementById('live-chat-open').classList.contains('messenger-build')) 
                                setTimeout(() => { comunicationWith.classList.remove('display-none')}, 1000);
                                
                    if(onlineSpace!==null) {
                        if(onlineSpace.classList.contains('display-none'))
                            if(document.getElementById('live-chat-open').classList.contains('messenger-build')) 
                                setTimeout(() => { 
                                    onlineSpace.classList.remove('display-none')
                                    //SET DOWN
                                    this.initMessagessTable()
                                }, 1000);
                        
                    }

                } else {

                    if(comunicationWith!==null) 
                        if(!comunicationWith.classList.contains('display-none'))
                            comunicationWith.classList.add('display-none')

                    if(onlineSpace!==null) 
                        if(!onlineSpace.classList.contains('display-none'))
                            onlineSpace.classList.add('display-none')
                    
                }
                
            },

            initMessagessTable() {
                let messageId = document.getElementById("messages-id");
                messageId.scrollTop = messageId.scrollHeight;
                this.readMessagess(this.clientId, this.brokerId)
            },

            readMessagess(  clientId=0,
                            brokerId=0) {

                if(clientId<1 || brokerId<1 ) return
                this.AXIOS.getMessagesChat(clientId, brokerId, 'oNewCommunication')
            },

            setViewAndNone()    {

                let liveChatOpen = document.getElementById('live-chat-open')
                if(liveChatOpen!==null)
                    if(liveChatOpen.classList.contains('messenger-build'))  return

                var doc = document.documentElement;
                var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

                let liveChat = document.getElementById('all')
                if(liveChat !== null) {

                    if(top > 159) {

                        liveChat.classList.remove('live-chat-none')

                        if(document.getElementById('live-chat-open').classList.contains('messenger-build'))
                            if(this.openChatOffer) this.showContentOffline(true, 4)

                    } else {
                        liveChat.classList.add('live-chat-none')
                        this.showContentOffline(false,5 )
                    }
                }                
            },

            newAdminMessage(element)   {

                if(element.key==='Enter') this.sendAdministrator()

            },

            sendAdministrator() {

                let eAdminNessage = document.getElementById('admin-message')
                if(eAdminNessage===null)    return

                let adminNessage =  eAdminNessage.value
                if(adminNessage.toString().trim().length<2) return

                document.getElementById('admin-message').value = ''
                setTimeout(() => {
                    this.AXIOS.sendMessage(     this.DT.stdFromJSDateTime(new Date()),
                                                1,
                                                this.choiceClientForAdmin,
                                                this.clientName,
                                                adminNessage  )
                }, 500);

                //1 is temporary
                setTimeout(() => {
                    //xxxxxxxxxxxxx
                    this.readMessagess(this.choiceClientForAdmin, 1) 
                }, 1000);

                //1 is temporary
                setTimeout(() => {  
                    console.log(this.oNewCommunication)
                    this.refreshMessagesBox(    'messages-admin', 
                                                this.oNewCommunication,
                                                this.choiceClientForAdmin,
                                                1)}, 1500);                
            },

            newMessage(e, from='')    {
                
                if(from==='isMessage') if(e.key==='Enter') this.sendOnline()


                let eChatIdentity = document.getElementById('chat-identity')

                let eNewMessage = document.getElementById('new-message')
                let eSendNewMessage = document.getElementById('send-new-message')

                if(eChatIdentity.classList.contains('warning-message')) eChatIdentity.classList.remove('warning-message')
                if(eNewMessage.classList.contains('warning-message')) eNewMessage.classList.remove('warning-message')

                let identity = eChatIdentity.value.toString().trim().length
                let message = eNewMessage.value.toString().trim().length

                if(identity>0  && identity < 5 )    eChatIdentity.classList.add('warning-message')
                if(message>0  && message < 2 )     eNewMessage.classList.add('warning-message')

                eSendNewMessage.classList.remove('send-new-message')
                eSendNewMessage.classList.remove('send-new-message-off')

                if(identity >= 5 && message >= 2)  {
                    eSendNewMessage.classList.add('send-new-message')
                } else {
                    eSendNewMessage.classList.add('send-new-message-off')
                }
            },

            msgOnly()   {

                let eIdentity = document.getElementById('client-identify')
                let eMessage = document.getElementById('client-message') 
                let eSendMessage = document.getElementById('send-message') 

                if(eIdentity.classList.contains('warning-message')) eIdentity.classList.remove('warning-message')
                if(eMessage.classList.contains('warning-message')) eMessage.classList.remove('warning-message')

                let identity = eIdentity.value.toString().trim().length
                let message = eMessage.value.toString().trim().length

                if(identity>0  && identity < 5 )    eIdentity.classList.add('warning-message')
                if(message>0  && message < 10 ) eMessage.classList.add('warning-message')


                eSendMessage.classList.remove('send-message')
                eSendMessage.classList.remove('send-message-off')
                if(identity >= 5 && message >= 10)  {
                    eSendMessage.classList.add('send-message')
                } else {
                    eSendMessage.classList.add('send-message-off')
                }
            },
            sendOnline()   {

                console.log('sendOnline()')
                let eChatIdentity = document.getElementById('chat-identity')
                let eNewMessage = document.getElementById('new-message')

                if(eChatIdentity===null || eNewMessage===null)  return

                let chatIdentity = eChatIdentity.value
                let newMessage =  eNewMessage.value

                if(chatIdentity.toString().trim().length<5) return
                if(newMessage.toString().trim().length<2) return

                
                if(this.clientId<1 || this.clientName.length===0) {
                    this.clientName = document.getElementById('chat-identity').value  //identify
                    this.AXIOS.newChatClient(   this.clientIP,
                                                this.clientName,
                                                this.URL.getHost(),
                                                this.DT.stdFromJSDateTime(new Date()),
                                                0, 0, 'clientId')                    
                }

                setTimeout(() => {
                    if(this.brokerId<1) this.brokerId = 1

                    let identity = document.getElementById('chat-identity').value
                    let message = document.getElementById('new-message').value
                    document.getElementById('new-message').value = ''

                    document.getElementById('send-new-message').classList.remove('send-new-message')
                    document.getElementById('send-new-message').classList.add('send-new-message-off')

                    this.AXIOS.sendMessage(     this.DT.stdFromJSDateTime(new Date()),
                                                this.clientId,
                                                this.brokerId,
                                                identity,
                                                message  )
                }, 500);

                setTimeout(() => {this.readMessagess(this.clientId, this.brokerId) }, 1000);

                setTimeout(() => {this.refreshMessagesBox(  'messages-id', 
                                                            this.oNewCommunication,
                                                            this.clientId,
                                                            this.brokerId)}, 1500);
            },
            sendOffline()   {

                let clientIdentify  = document.getElementById('client-identify')
                let clientMessage = document.getElementById('client-message')

                if(clientIdentify===null || clientMessage===null)   return

                if(clientIdentify.value.toString().trim().length<5)   return
                if(clientMessage.value.toString().trim().length<10)   return

                let eComunicationWith = document.getElementById('comunication-with')
                if(eComunicationWith!==null) eComunicationWith.classList.add('display-none')

                let eComunicationAfter = document.getElementById('comunication-after')
                if(eComunicationAfter!==null) eComunicationAfter.classList.remove('display-none')

                setTimeout(() => {
                    let eComunicationAfter = document.getElementById('comunication-after')
                    if(eComunicationAfter!==null) eComunicationAfter.classList.add('display-none')
                    let eMessage = document.getElementById('client-message') 
                    if(eMessage!==null) eMessage.value=''
                    this.liveChatClose()
                }, 10000);

                if(this.clientId<1 || this.clientName.toString().trim().length===0) {

                    this.clientName = document.getElementById('client-identify').value  //identify
                    this.AXIOS.newChatClient(   this.clientIP,
                                                this.clientName,
                                                this.URL.getHost(),
                                                this.DT.stdFromJSDateTime(new Date()),
                                                0, 0, 'clientId')
                }

                setTimeout(() => {
                    if(this.brokerId<1) this.brokerId = 1

                    let identity = document.getElementById('client-identify').value
                    let message = document.getElementById('client-message').value

                    this.AXIOS.sendMessage(     this.DT.stdFromJSDateTime(new Date()),
                                                this.clientId,
                                                this.brokerId,
                                                identity,
                                                message  )                    
                }, 1000);
            },
            createStyle(oStyles=[], identify='') {

                if(typeof oStyles !== 'object')             return ''
                if(identify.toString().trim().length===0)   return ''

                let returnStyles = ''
                for( let inStyle of oStyles) 
                    if(identify.toLowerCase()===inStyle.name.toLowerCase())   
                        for( let inline of inStyle.style) returnStyles += inline.styleSet

                return returnStyles
            },            
        },
        mounted () {
            this.backgroundEvents()
            this.showContentOffline(false, 6)
        },
    }
</script>

<style lang="scss" scoped>


$msg-start-height:75px;
$msg-start-width:75px;

$msg-max-width:300px;
$msg-max-height:680px;

$msg-width:55px;
$msg-height:55px;

$icon-width:45px;
$icon-height:45px;

.messages-admin   {
    width:95%;
    margin:10px auto 0 auto;
    height:450px;
    overflow-y: scroll;
    overflow-x: hidden;    
    text-align: left;
    background-color: $std-col-gentle;    //super
}


.messages   {
    width:95%;
    margin:10px auto 0 auto;
    height:370px;
    overflow-y: scroll;
    overflow-x: hidden;    
    text-align: left;
    background-color: $std-col-gentle;    //super
}

#messages-id::-webkit-scrollbar {
    width: 12px;
}

#messages-id::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

#messages-id::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}



#messages-admin::-webkit-scrollbar {
    width: 12px;
}

#messages-admin::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

#messages-admin::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}


/*
.comunication-with  {
    background-color: yellow;
    height:70px;
}
*/

.display-none  {
    display: none;
}


.live-chat-open-in  {
    //display: none;

    //border:solid 1px yellow;
    border-radius: 35px 35px 0 0;   
    background-color: $std-bg-super;
    //
    width:100%;
    height:80px;
}

.live-chat-open-offline-content  {   //head chat
    margin:5px auto 0 auto;
    font-family: $std-family;
    font-size:.9em;
    font-weight: 600;
    color:$std-black;
    padding:0 5px;
    height:70px;
}

.live-chat-open-online-content  {
    
    margin:5px auto 0 auto;
    height:75px;
}

.live-chat-open-online-content-left    {
    clear: both;
    float:left;
    border:solid 0px red;
    width:75%;
    height:75px;
    &-name  {
        margin:5px 0 0 0;
        font-family: $std-family;
        color:$std-black;
        font-size:.9em;
        font-weight:600;
    }
    &-status  {
        margin:0px 0 0 0;
        font-family: $std-family;
        color:$std-black;
        font-size:.9em;
        font-weight:500;        
    }
    &-appel  {
        margin:0px 0 0 0;
        font-family: $std-family;
        color:$std-black;
        font-size:.9em;
        font-weight:600;        
    }    
}

.online  {
    font-family: $std-family;
    color:$std-online;
    font-weight:700;
}

.offline  {
    font-family: $std-family;
    color:$std-col-warn;
    font-weight:700;
}

.comunication-offline   {
    margin:0px;
    border:solid 0px red;
}

.live-chat-open-online-content-right    {
    float:left;
    width:25%;
    height:75px;
    border:solid 0px green;
}



.live-chat-open-photo   {
    width:70px;
    border-radius:50px;
    height:auto;
}


.messenger-build  {
    border:solid 2px $std-black;
    height:$msg-max-height;
    width:$msg-max-width;
    position: fixed;
    //background-color: $std-col;
    background-color: $std-col-gentle;    //super
    border-radius:35px;
    top: 20%;
    right: 1%;    
    text-align: center;
    animation:  buildMessengerWin 1s;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);    
}
@keyframes buildMessengerWin {
    0% {
        height:$msg-start-height;
        border-radius:50px;
    }
    100% {
        height:$msg-max-height;
        border-radius:35px;
    }
}

.messenger  {
    height:$msg-start-height;
    width:$msg-start-width;
    z-index: 999;
    position: fixed;
    background-color: $std-col;
    //background-color: $std-col-gentle;    //super
    border-radius:50px;
    top: 20%;
    right: 1%;    
    text-align: center;
    padding: 10px;
}

.messenger-hover    {
    animation:  beforeOpenChat 1s;
    width: $msg-max-width;    
}
@keyframes beforeOpenChat {
    from {width:$msg-start-width}
    to {width:$msg-max-width;  }
}

.messenger-not-hover    {
    animation:  afterOpenChat  1s;
    width: $msg-start-width;    
}
@keyframes afterOpenChat {
    from {width:$msg-max-width;}
    to {width:$msg-start-width;}
}


.messenger-in  {
    clear:both;
    float: left;
    width: $msg-width;
    height:$msg-height;    
    border-radius:50px;
    z-index: 1001;
    background-color: $std-white;
    padding:2px 0 0 0;
    
}

.messenger-in-right  {
    float: left;
    padding:0 50px 0 50px;
}

.messenger-icon  {
    width: $icon-width;
    height: $icon-height;
    border-radius:50px;
}


.messenger-icon-animate  {
    animation: rotate 2s infinite;
}
@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.messenger-in-right-add {
    width:200px;
    padding: 0px;
    height:50px;
    text-align: center;
    font-family: $std-family;
    font-size: 1em;
    font-weight:600;
    color: $std-white;
    //color: $std-black;
    opacity:1;
    animation: setOpacity 2s infinite;
}
@keyframes setOpacity {
    from    {opacity:0;}
    to      {opacity:1;}
}


.messenger-in-right-add-static {
    width:200px;
    padding: 0px;
    height:50px;
    text-align: center;
    font-family: $std-family;
    font-size: 1em;
    font-weight:600;
    color: $std-white;
    //color: $std-black;
    opacity:1;
}



.startChat   {
    text-decoration: none !important;
    display:  block;
    cursor:pointer;    
}

.startChat::after {
    content: '';
    display: block;
    width: 0%;
    height: 1px;
    background: transparent;
    border:0px;
    transition: width .3s;
    -webkit-transition: color .3s; /* For Safari 3.0 to 6.0 */
    transition: color .3s; /* For modern browsers */
}

.startChat:hover::after {
    transition: width .3s;
    background: $std-white;
    //background: $std-black;
    margin: 0;
    border:0px;
    width: 100%;
}

.comunication-offline   {
    font-family: $std-family;
    font-size: .9em;
    font-weight:500;
    color: $std-black;    
    &.content-1   {
        padding:10px 10px 0 10px;
        text-align: justify;
    }
    &.content-2   {
        padding:10px 10px 0 10px;
        text-align: justify;
    }    
    &.content-3   {
        padding:10px 10px 0 10px;
        text-align: justify;
    }        
    &.content-4   {
        padding:10px 10px 0 10px;
        text-align: justify;
    }            
    &.content-5   {
        padding:10px 10px 0 10px;
        text-align: left;
    }                
    &.content-6   {
        padding:10px 10px 0 10px;
        text-align: left;
    }                    
    &.content-7   {
        text-align: center;
        font-family: $std-family;
        font-size: 1.1em;
        font-weight: 600;
    }                        
    &.offline   {
        color:$std-col-warn;
        font-weight:600;
    }
    &.online   {
        color:$std-online;
        font-weight:600;
    }    
}

.live-chat-none {
    display: none;
}

.chat-identity {

    width:97%;
    margin:5px auto 5px auto;
}

.welcome {
    width:97%;
    margin:5px auto 5px auto;
}

.message-only   {
    width:97%;
    margin:5px auto 5px auto;
    resize: none;
    height:170px;    
}

.warning-message    {
    background-color: $std-col-light-in;
    color:$std-col-warn;
}

.send-message-off    {
    color: #d3c9c0;
}

.send-message    {
    color: $std-black;
    cursor: pointer;
}

.send-message::after {
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background: transparent;
    border:0px;
    transition: width .3s;
    -webkit-transition: color .3s; /* For Safari 3.0 to 6.0 */
    transition: color .3s; /* For modern browsers */
}

.send-message:hover::after {
    transition: width .3s;
    background: $std-black;
    //background: $std-black;
    margin: 0;
    border:0px;
    width: 100%;
}

.start-send   {
    max-width:80%;
    margin:0 auto;
}

.comunication-after-send    {
    border:solid 0px red;
}

.after-send {
    width:90%;
    margin:30px auto 0 auto;
    font-family:$std-family;
    color:$std-black;
    font-size:1em;
    font-weight: 600;
}

.online   {
    color:$std-online;
    font-weight:600;
}    

.adminstrator-chat-space    {
    height:550px;
}

.online-chat-space  {
    height:550px;
}

.chat    {
    font-family: $std-family;
    font-size: .9em;
    font-weight:500;
    color: $std-black;    
    &.sector-1  {
        text-align: left;
        margin:5px 0 0 0;
        text-align: center;
    }
}


.new-message-input    {
    width:97%;
    margin:5px auto 5px auto;
    resize: none;
    height:100px;    
    
}

.send-new-message-off   {
    font-family: $std-family;
    width:70%;
    margin:0 auto;
    font-size: 1em;
    font-weight:700;
    color: #d3c9c0;
}

.send-new-message   {
    font-family: $std-family;
    font-size: 1em;
    font-weight:700;
    color: $std-black;
    width:70%;
    margin:0 auto;
    cursor:pointer;
}

.send-new-message::after {
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background: transparent;
    border:0px;
    transition: width .3s;
    -webkit-transition: color .3s; /* For Safari 3.0 to 6.0 */
    transition: color .3s; /* For modern browsers */
}

.send-new-message:hover::after {
    transition: width .3s;
    background: $std-black;
    //background: $std-black;
    margin: 0;
    border:0px;
    width: 100%;
}

.select-senders-all {
    margin:30px auto 0 auto;
    border-bottom:dotted 2px $std-black;
    width: 90%;
    height:30px;
    cursor: pointer;
}

.select-senders {
    clear:both;
    float:left;
    width:90%;
    height:30px;
    font-family: $std-family;
    font-size: 1em;
    font-weight:600;
    color: $std-black;

}
.icon  {
    float:left;
    width:10%;
    height:30px;
}

.sender {
    margin:5px auto 0 auto;
    width:95%;
    height:30px;
}

.sender-del {
    
    float: left;
    width:15%;
    height:30px;
    text-align: center;
    float: left;
    font-family: $std-family;
    font-size: .8em;
    font-weight:500;
    color: $std-col-warn;
    cursor: pointer;    
}

.sender-in {
    clear: both;
    float: left;
    font-family: $std-family;
    font-size: 1em;
    font-weight:600;
    color: $std-black;
    width:85%;
    height:30px;
    cursor: pointer;    
}


#senders-selector-content::-webkit-scrollbar {
    width: 12px;
}

#senders-selector-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

#senders-selector-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.selector-content   {
    overflow-y: scroll;
    overflow-x: hidden;    
    clear: both;
    background-color: #dec5ad;
    width:90%;
    margin:0 auto;
    height: 0px;
    display: inherit;
    position: absolute;
    left:5%;
    z-index: 1500;
    text-align: left;
}

.selector-open-animation   {
    height:200px;
    animation:  open-selector-content .5s;
}
@keyframes open-selector-content {
    0% {
        height:0px;
    }
    100% {
        height:200px;
    }
}

.selector-close-animation   {
    height:0px;
    animation:  close-selector-content .5s;
}
@keyframes close-selector-content {
    0% {
        height:200px;
    }
    100% {
        height:0px;
    }
}


</style>